<template>
  <div>
    <TheSaleRepresentative />
  </div>
</template>
<script>
export default {
  components: {
    TheSaleRepresentative: () =>
      import("@/components/global/TheSaleRepresentative.vue")
  }
};
</script>
